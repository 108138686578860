.footer {
  position: relative;
  width: 100%;
  background: rgba(80, 110, 181);
  min-height: 100px;
  color: #fff;
  margin-top: 8rem;
}

.logo {
  height: 70px;
}

.content {
  padding: 3% 3%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.wave {
  position: absolute;
  top: -99px;
  left: 0;
  width: 100%;
  height: 120px;
  background: url('wave.png');
  background-size: 1000px 120px;
}

.wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWaves 4s linear infinite;
}

.wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animate 4s linear infinite !important;
}

.wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWaves 3s linear infinite;
}

.wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animate 3s linear infinite;
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

.copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(0, 185, 242);
  width: 100%;
  height: 100px;
}

.copyright p {
  color: #fff;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
}

.asc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.social {
  color: #fff;
  display: flex;
  gap: 1.4rem;
}

.social svg {
  --size: 44px;
  width: var(--size);
  height: var(--size);
  fill: #fff;
}

.links ul {
  list-style: none;
  padding: 0;
}

.list-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
}

.contact {
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
}

.contact svg {
  width: 20px;
  height: 20px;
}

a {
  color: #fff;
  text-decoration: none;
}
